<template>
    <v-row>
        <v-col cols="12">
            <v-btn @click="salvar" fixed bottom right fab color="primary"><v-icon dark>mdi-content-save</v-icon></v-btn>
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* Franquia:" v-model="registro.franquia" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* Razão social / Nome completo:" v-model="registro.razao_social_nome_completo" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* CNPJ / CPF:" v-model="registro.cnpj_cpf" v-mask="['###.###.###-##', '##.###.###/####-##']" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* CEP:" v-model="registro.cep" v-mask="'#####-###'" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-autocomplete hide-details label="* Estado:" :items="selectEstado" v-model="registro.id_estado" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-autocomplete hide-details label="* Cidade:" :items="selectCidade" v-model="registro.id_cidade" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* Bairro:" v-model="registro.bairro" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* Logradouro:" v-model="registro.logradouro" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="Número:" v-model="registro.numero" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="Complemento:" v-model="registro.complemento" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* E-mail:" v-model="registro.email" type="email" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* Telefone:" v-model="registro.telefone" v-mask="['(##)  ####-####', '(##) #.####-####']" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="WhatsApp:" v-model="registro.whatsapp" v-mask="['(##)  ####-####', '(##) #.####-####']" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="Facebook:" v-model="registro.facebook" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="Instagram:" v-model="registro.instagram" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-select hide-details label="Dia de vencimento da fatura:" :items="selectVencimentoFatura" v-model="registro.vencimento_fatura" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-select hide-details label="* Ativa:" :items="[{value : true, text : 'Sim'}, {value : false, text : 'Não'}]" v-model="registro.ativo" /></v-col>
                        </v-row>
                    </v-form>
                    <v-alert v-if="erro" type="error" class="mt-5"><div v-html="erro"></div></v-alert>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios'
import {mask} from 'vue-the-mask'
import {mapState} from 'vuex'

export default {
    name: "FranquiaForm",
    props : ['id_franquia'],
    directives : {mask},
    data() {
        return {
            registro : {},
            erro : null,

            selectGrupo : [],
            selectEstado : [],
            selectCidade : [],
            selectVencimentoFatura : [
                {value : 5, text: '5'},
                {value : 7, text: '7'},
                {value : 10, text: '10'},
                {value : 13, text: '13'},
                {value : 15, text: '15'}
            ]
        }
    },
    computed : {
        ...mapState(['baseUrl', 'apiUrl'])
    },
    methods : {
        getFranquia() {
            axios.post(`${this.baseUrl}franquia/get`, {id_franquia : this.id_franquia}).then((res) => {
                if(res.data.erro) {
                    this.$router.push({path : '/franquia'})
                }
                this.registro = {
                    ...res.data,
                    id_estado         : parseInt(res.data.id_estado),
                    id_cidade         : parseInt(res.data.id_cidade),
                    vencimento_fatura : parseInt(res.data.vencimento_fatura),
                    ativo             : res.data.ativo == 't'
                }
            })
        },
        selectCidadeListar() {
            if(this.registro.id_estado) {
                return axios.post(`${this.baseUrl}endereco/cidade_listar`, {id_estado : parseInt(this.registro.id_estado)}).then((res) => {
                    this.selectCidade = res.data.lista.map((c) => {return {
                        value : parseInt(c.id_cidade),
                        text : c.cidade
                    }})
                })
            } else {
                this.selectCidade = []
            }
        },
        selectEstadoListar() {
            return axios.post(`${this.baseUrl}endereco/estado_listar`).then((res) => {
                this.selectEstado = res.data.lista.map((rt) => {
                    return {value: parseInt(rt.id_estado), text: rt.estado}
                })
            })
        },
        salvar() {
            if(!this.id_franquia) {
                axios.post(`${this.apiUrl}franquia/nova`, {...this.registro, cnpj_cpf : this.mascaraRemover(this.registro.cnpj_cpf), cep : this.mascaraRemover(this.registro.cep), telefone : this.mascaraRemover(this.registro.telefone), whatsapp : this.mascaraRemover(this.registro.whatsapp)}).then( (res) => {
                    this.erro = null
                    this.$router.push({path : '/franquia/gerenciar/' + res.data})
                }).catch(e => {
                    this.erro = this.erroFormatar(e)
                })
            } else {
                axios.post(`${this.apiUrl}franquia/alterar`, {...this.registro, cnpj_cpf : this.mascaraRemover(this.registro.cnpj_cpf), cep : this.mascaraRemover(this.registro.cep), telefone : this.mascaraRemover(this.registro.telefone), whatsapp : this.mascaraRemover(this.registro.whatsapp)}).then( () => {
                    this.erro = null
                    this.$router.push({path : '/franquia/gerenciar/' + this.id_franquia})
                }).catch(e => {
                    this.erro = this.erroFormatar(e)
                })
            }
        },
        async init() {
            this.registro = {ativo : true}
            await this.selectEstadoListar()
            if(this.id_franquia) {
                await this.getFranquia()
            }
        }
    },
    watch : {
        'registro.id_estado' : function () {
            this.selectCidadeListar().then((res) => {
                if(res.filter(v => v.value == this.registro.id_cidade).length == 0) {
                    this.registro.id_cidade = null
                }
            })
        }
    },
    activated : function() {
        this.init()
    }
}
</script>

<style scoped>

</style>
